import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

function getRoutes(requireComponent) {
    let routes = []
    requireComponent.keys().forEach(filename => {
        // filename 文件
        // 第i个组件
        const config = requireComponent(filename)
        // 组件名
        if (config.default.path) {
            routes.push({
                path: config.default.path,
                name: config.default.name,
                // component: ()=>import(`@/components/${sub.componentName}`)
                component: config.default
            })
        }
    })
    return routes
}

export default new Router({
    mode: 'history',
    routes: [
        {path: '/', name: 'index', redirect: '/login'},
        {path: '/login', component: () => import(`@/common/components/auth/login`)},
        {path: '/logout', component: () => import(`@/common/components/auth/logout`)},
        {
            path: '/main', component: () => import(`@/common/components/layouts/layout_admin`),
            // 自动发现路由，再扩展几个默认页面
            children: getRoutes(require.context(`@/pages`, true, /\.vue$/)).concat([
                {path: '', name: 'main', redirect: 'dashboard'},
                {path: 'routes', component: () => import(`@/common/example/pages/getAllRoutes`)},
                {path: 'password', component: () => import(`@/common/components/auth/password`)},
                {path: 'message', component: () => import(`@/common/components/auth/message`)},
                {path: '*', component: () => import(`@/common/components/notfound`)}
            ]),
        },
        {path: '*', component: () => import(`@/common/components/notfound`)}
    ]
})

