<template>
    <div>
        <el-page-header @back="$router.go(-1)" :content="$t('menu.company_form')"/>
        <el-form :model="form" :rules="rules" ref="form" label-width="120px"
                 style="padding-bottom:80px;margin-top:20px"
        >
            <el-row class="title">
                <span>企业基础信息</span>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-col :span="8">
                        <el-form-item label="企业名称" prop="company_name">
                            <el-input v-model="form.company_name">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="企业类型">
                            <el-input v-model="form.type">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="经营领域">
                            <el-input v-model="form.business_scope">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-row>
            <el-row class="title">
                <span>联系人信息</span>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="联系人姓名" prop="contact_name">
                        <el-input v-model="form.contact_name">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="联系人电话" prop="contact_phone">
                        <el-input v-model="form.contact_phone">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="title">
                <span>初始部门</span>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="部门名称" prop="department_name">
                        <el-input v-model="form.department_name">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="部门代码" prop="department_code">
                        <el-input v-model="form.department_code">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="title">
                <span>初始账号</span>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="form.username">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="密码" prop="password">
                        <el-input v-model="form.password">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="邮箱">
                        <el-input v-model="form.email">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="title">
                <el-col :span="24" style="text-align: center;padding:5px">
                    <el-button type="primary" @click="formSubmit">确 定 开 通</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: 'company_form',
        path: '/main/company/form',
        data() {
            return {
                form: {
                    company_name: '',
                    type: '',
                    business_scope: '',
                    contact_name: '',
                    contact_phone: '',
                    department_name: '',
                    department_code: '',
                    username: '',
                    password: '',
                },
                rules: {
                    company_name: [
                        {required: true, message: '此项内容为必填项', trigger: 'blur'},
                    ],
                    contact_name: [
                        {required: true, message: '此项内容为必填项', trigger: 'blur'},
                    ],
                    contact_phone: [
                        {required: true, message: '此项内容为必填项', trigger: 'blur'},
                    ],
                    department_name: [
                        {required: true, message: '此项内容为必填项', trigger: 'blur'},
                    ],
                    department_code: [
                        {required: true, message: '此项内容为必填项', trigger: 'blur'},
                    ],
                    username: [
                        {required: true, message: '此项内容为必填项', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '此项内容为必填项', trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            async formSubmit() {
                const valid = await this.$refs['form'].validate((valid))
                if (!valid) {
                    return
                }
                try {
                    const resp = await this.$http.post('/admin/company/', this.form)
                    if (resp.data.status === 200) {
                        this.$message.success("保存成功")
                        await this.$router.push('/main/company/index')
                    } else {
                        this.$message.error(resp.data.msg)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
        },
        mounted() {
        },
    }
</script>

<style scoped>
    /deep/ .el-table th.is-leaf {
        border-bottom: 1px solid #EBEEF5;
        background-color: aliceblue;
    }

    .title {
        margin-bottom: 10px;
        background-color: aliceblue;
        font-size: 15px;
        padding: 15px;
    }

    .tabeldata {
        border: 1px solid red;
    }

    .el-upload--picture-card {
        line-height: inherit;
        display: inherit;
        text-align: center;
        align-items: center;
    }
</style>
