<template>
    <zk-admin-table :title="$t('menu.device_ap')" @search="handleSearch" :table="table"
                    :loading="loading">
        <div slot="search">
                <div style="text-align: left">
                    <el-button size="small" type="primary" icon="el-icon-plus" @click="dialogVisible = true">新增</el-button>
                    <el-button size="small" type="warning" icon="el-icon-download" @click="handleExport">导出SN</el-button>
                    <el-button size="small" type="danger" icon="el-icon-remove" @click="handleDelete">删除</el-button>
                </div>
        </div>
        <el-table @selection-change="handleSelectionChange" stripe :data="table.data"
                  style="width: 100%"
                  v-loading="loading" border height="100%">
            <el-table-column type="selection" align="center" width="50"/>
            <el-table-column align="center"
                             v-for="(v, idx) in table.columns"
                             :prop="v.field"
                             :label="v.title"
                             :width="v.width"
                             :formatter="v.formatter"
                             :filters="v.filters"
                             :key="idx">
            </el-table-column>
        </el-table>
        <div slot="dialog">
            <el-dialog
                    title="提示"
                    :visible.sync="dialogVisible"
                    width="30%">
                <div>
                    <span>请输入生产数量</span>
                    <el-input placeholder="" v-model.number="produce" clearable/>
                </div>
                <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="doProduct">确 定</el-button>
              </span>
            </el-dialog>
        </div>
    </zk-admin-table>
</template>

<script>
    export default {
        name: 'device_ap',
        path: '/main/device/ap',
        data() {
            return {
                loading: false,
                dialogVisible: false,
                doctorList: [],
                produce: null,
                sn_alias: null, //'',
                form: {
                },
                multipleSelection: [],
                params: {
                    page: 1,
                    pageSize: 20,
                },
                table: {
                    buttons: [],
                    search: [],
                    columns: [
                        {title: 'SN', field: 'sn', width: ''},
                        {title: '创建时间', field: 'created_date', width: '', formatter: null},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
            }
        },
        methods: {
            async handleExport() {
                this.$http.get(`/admin/device/temperature/ap/export/`, {
                    responseType: 'arraybuffer',
                }).then(res => {
                    let url = window.URL.createObjectURL(new Blob([res.data],{type:"application/vnd.ms-excel"}))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    document.body.appendChild(link)
                    link.click()
                }).catch(err => {
                    console.log(err)
                })
            },
            async handleDelete() {
                let ids = []
                this.multipleSelection.forEach(e=>{ids.push(e.device_id)})
                const resp = await this.$http.delete(`/admin/device/temperature/ap/`, {params: {id: ids}})
                console.log(resp)
                if (resp.data.status !== 200) {
                    this.$message.error(resp.data.message)
                } else {
                    this.$message.success("删除成功")
                }
                this.getData()
            },
            async doProduct() {
                if (!this.produce) {
                    this.$message.error("请输入生产数量")
                    return
                }
                if (this.produce > 5000) {
                    this.$message.error("单次生产数量不能大于5000")
                    return
                }
                const resp = await this.$http.post("/admin/device/temperature/ap/produce/", {produce: this.produce, sn_alias: this.sn_alias})
                if (resp.data.status !== 200) {
                    this.$message.error(resp.data.message)
                } else {
                    this.$message.success("新建成功")
                }
                this.dialogVisible = false
                this.getData()
            },
            async handleSet() {
                this.getData()
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            handleSearch(e) {
                this.params.page = e.page
                this.params.pageSize = e.pageSize
                this.getData()
            },
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/admin/device/ap/`, {params: this.params})
                    this.table.data = resp.data.data.records || []
                    this.table.total = resp.data.data.total
                } catch (e) {
                    console.log(111, e)
                    this.$message.error(e.message)
                }
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .el-form-item {
        margin: 0;
    }
</style>
