<template>
    <zk-admin-table title="系统日志" @search="handleSearch" :table="table"
                    :loading="loading">
        <div slot="search">
            <el-select size="small" v-model="form.doctor" placeholder="请选择">
                <el-option :label="item.name" :value="item.id" v-for="item in doctorList" :key="item.id"/>
            </el-select>
            <el-button size="small" style="margin-left: 10px" type="primary" @click="handleSet">筛选</el-button>
        </div>
        <el-table @selection-change="handleSelectionChange" @filter-change="filterChange" stripe :data="table.data" style="width: 100%"
                  v-loading="loading" border height="100%">
            <el-table-column type="selection" align="center" width="50"/>
            <el-table-column align="center"
                             v-for="(v, idx) in table.columns"
                             :prop="v.field"
                             :label="v.title"
                             :width="v.width"
                             :formatter="v.formatter"
                             :filters="v.filters"
                             :key="idx">
            </el-table-column>
        </el-table>
    </zk-admin-table>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                doctorList: [],
                form: {
                    doctor: '',
                    multipleSelection: [],
                },
                params: {
                    page: 1,
                    pageSize: 20,
                    doctor: []
                },
                table: {
                    buttons: [],
                    search: [],
                    columns: [
                        {title: '时间', field: 'company_name', width: '200'},
                        {title: '类型', field: 'contact_name', width: '200', formatter: null},
                        {title: '内容', field: 'founded_date', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
            }
        },
        methods: {
            async handleSet() {
                const resp = await this.$http.post('/admin/set/doctor/', this.form)
                console.log(resp)
                this.getData()
            },
            handleSelectionChange(val) {
                this.form.multipleSelection = val;
            },
            handleSearch(e) {
                this.params.page = e.page
                this.params.pageSize = e.pageSize
                this.getData()
            },
            filterChange(e) {
                this.params.doctor = e['el-table_1_column_4']
                this.getData()
            },
            getData() {
                this.loading = true
                this.$http.get(`/companies/`, {params: this.params}).then(resp => {
                    this.table.data = resp.data || []
                    this.table.total = this.table.data.length
                    this.loading = false
                })
            }
        },
        mounted() {
            // this.getDoctorList()
            // this.getData()
        }
    }
</script>

<style lang="scss" scoped>
</style>
