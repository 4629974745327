<template>
    <zk-admin-table title="分配社区" @search="handleSearch" :table="table" :loading="loading">
        <div slot="search">
            <el-row>
                <el-col :span="12" style="text-align: left">
                    <el-select size="small" v-model="form.community" placeholder="请选择">
                        <el-option label="不分配" value=""/>
                        <el-option :label="item" :value="item" v-for="item in community" :key="item"/>
                    </el-select>
                    <el-button size="small" style="margin-left: 10px" type="primary" @click="handleSet">分配</el-button>
                </el-col>
                <el-col :span="12" style="text-align: right">
                    <!--                    <el-input v-model="keywords" size="small" placeholder="输入关键字搜索"/>-->
                </el-col>
            </el-row>
        </div>
        <el-table @selection-change="handleSelectionChange" @filter-change="filterChange" stripe :data="table.data"
                  style="width: 100%" v-loading="loading" border height="100%">
            <el-table-column type="selection" align="center" width="50"/>
            <el-table-column align="center"
                             v-for="(v, idx) in table.columns"
                             :prop="v.field"
                             :label="v.title"
                             :width="v.width"
                             :formatter="v.formatter"
                             :filters="v.filters"
                             :key="idx">
            </el-table-column>
        </el-table>
    </zk-admin-table>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                community: ['花园楼社区', '北旺乡', '银河南路'],
                form: {
                    community: '',
                    multipleSelection: [],
                },
                params: {
                    page: 1,
                    pageSize: 20,
                    doctor: []
                },
                table: {
                    buttons: [],
                    search: [],
                    columns: [
                        {title: '名称', field: 'name', width: '200'},
                        {title: '社区', field: 'community', width: '200', formatter: null},
                        {
                            title: '医生',
                            field: 'doctor',
                            width: '120',
                            filters: [{text: '焦莹', value: '焦莹'}, {text: '王玉君', value: '王玉君'}],
                        },
                        {title: '创建时间', field: 'created', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
            }
        },
        methods: {
            async handleSet() {
                const resp = await this.$http.post('/admin/set/community/', this.form)
                console.log(resp)
                this.getData()
            },
            handleSelectionChange(val) {
                this.form.multipleSelection = val;
            },
            handleSearch(e) {
                this.params.page = e.page
                this.params.pageSize = e.pageSize
                this.getData()
            },
            filterChange(e) {
                this.params.doctor = e['el-table_1_column_4']
                this.getData()
            },
            getData() {
                this.loading = true
                setTimeout(function () {
                    this.$http.get(`/admin/patient/`, {params: this.params}).then(resp => {
                        this.table.data = resp.data.data.data || []
                        this.table.total = resp.data.data.total || 0
                        this.loading = false
                    })
                }.bind(this), 200)
            },
            getDoctorList() {
                this.$http.get(`/admin/doctor/`).then(resp => {
                    console.log(resp.data)
                    let drc = []
                    resp.data.data.forEach(e=>{
                        drc.push({text:e.name, value:e.name})
                    })
                    this.table.columns[2].filters = drc
                })
            },
        },
        mounted() {
            this.getDoctorList()
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
</style>
