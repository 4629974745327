import Vue from 'vue'
// import VueI18n from 'vue-i18n'
import App from './common/App.vue'
import './common'
import router from './router/index'
import store from './common/example/store'
import http from "./plugins/http"
import i18n from './common/i18n'

Vue.config.productionTip = false
Vue.prototype.$http = http

new Vue({
    render: h => h(App),
    router,
    store,
    i18n
}).$mount('#app')
