const data = {
    menuItems: [
        {
            id: '10',
            label: 'menu.dashboard',
            path: '/main/dashboard',
            icon: 'el-icon-view',
        },
        {
            id: '30',
            label: 'menu.company',
            path: '/main/company',
            icon: 'el-icon-film',
            children: [
                {
                    id: '30-2',
                    label: 'menu.company_index',
                    path: '/main/company/index',
                },
                // {
                //     id: '30-1',
                //     label: 'menu.company_form',
                //     path: '/main/company/form',
                // },
                // {
                //     id: '30-4',
                //     label: 'menu.company_overdue',
                //     path: '/main/company/overdue',
                // },
            ]
        },
        {
            id: '40',
            label: 'menu.device',
            path: '/main/device',
            icon: 'el-icon-film',
            children: [
                {
                    id: '40-1',
                    label: 'menu.device_index',
                    path: '/main/device/beacon',
                },
                {
                    id: '40-2',
                    label: 'menu.device_ap',
                    path: '/main/device/ap',
                },
            ]
        },
        // {
        //     id: '100',
        //     label: '统计报表',
        //     path: '/main/statistics',
        //     icon: 'el-icon-data-line',
        // },
        // {
        //     id: '105',
        //     label: '导入导出',
        //     path: '/main/statistics',
        //     icon: 'el-icon-data-line',
        //     children: [
        //         {
        //             id: '105-1',
        //             label: 'XX数据导入',
        //             path: '/main/data/appoint/import',
        //             icon: 'el-icon-menu',
        //         },
        //         {
        //             id: '105-2',
        //             label: 'XX数据导出',
        //             path: '/main/data/appoint/export',
        //             icon: 'el-icon-menu',
        //         },
        //     ]
        // },
        // {
        //     id: '110',
        //     label: '系统设置',
        //     path: '/main/settings',
        //     icon: 'el-icon-setting',
        //     children: [
        //         {
        //             id: '110-2',
        //             label: 'xx设置',
        //             path: '/main/settings/area',
        //             icon: 'el-icon-menu',
        //         },
        //         {
        //             id: '110-5',
        //             label: 'xx设置',
        //             path: '/main/settings/menu',
        //             icon: 'el-icon-menu',
        //         },
        //     ]
        // },
        {
            id: '120',
            label: 'menu.oplog',
            path: '/main/oplog',
            icon: 'el-icon-notebook-1',
        }
    ],
    filterArrForKey(arr, key, value) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterArrForKey(item.children, key, value)
                }
            }
            if (value.includes(item[key])) {
                return item
            } else {
                return null
            }
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    },
    filterChecked(arr, key) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterChecked(item.children)
                }
            }
            return item[key]
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    }
}

export default data
