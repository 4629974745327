<template>
    <zk-admin-table title="用户管理" :table="table" :loading="loading">
        <div slot="search">
            <div style="text-align: left">
                <el-button size="small" style="margin-left: 10px" type="primary" @click="handleAdd">新建</el-button>
            </div>
        </div>
        <el-table @selection-change="handleSelectionChange" stripe :data="table.data"
                  style="width: 100%"
                  v-loading="loading" border height="100%">
            <el-table-column type="selection" align="center" width="50"/>
            <el-table-column align="center"
                             v-for="(v, idx) in table.columns"
                             :prop="v.field"
                             :label="v.title"
                             :width="v.width"
                             :formatter="v.formatter"
                             :filters="v.filters"
                             :key="idx">
            </el-table-column>
            <el-table-column align="center" width="120" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="handleUpdate(scope.row)">设置</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div slot="dialog">
            <el-dialog title="设置" :visible.sync="dialogVisible" width="40%">
                <el-form ref="form" :model="form" label-width="140px" size="small">
                    <el-form-item label="用户类型" prop="type"
                                  :rules="{ required: true, message: '设备号不能为空', trigger: 'change'}">
                        <el-select v-model="form.type" placeholder="请选择用户类型">
                            <el-option label="管理员" value="管理员"></el-option>
                            <el-option label="普通用户" value="普通用户"></el-option>
                            <el-option label="接口账号" value="接口账号"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="用户名" prop="login"
                                  :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model="form.login"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password"
                                  :rules="{ required: false, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model="form.password" placeholder="留空则保留原密码"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="phone"
                                  :rules="{ required: false, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model="form.phone"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="handleUpdateSubmit">确 定</el-button>
                  </span>
            </el-dialog>
        </div>
    </zk-admin-table>
</template>

<script>
    export default {
        name: 'company_user',
        path: '/main/company/:id/user',
        data() {
            return {
                loading: false,
                dialogVisible: false,
                multipleSelection: [],
                form: {},
                table: {
                    buttons: [],
                    search: [],
                    columns: [
                        {title: '用户名', field: 'login', width: '200'},
                        {title: '用户类型', field: 'type', width: '200', formatter: null},
                        {title: '联系电话', field: 'phone', width: '120', filters: []},
                        // {title: 'email', field: 'email', width: '290'},
                        {title: '创建时间', field: 'created_date', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
            }
        },
        methods: {
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            handleAdd() {
                this.form = {}
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.form = row
                this.dialogVisible = true
            },
            async handleUpdateSubmit() {
                try {
                    const valid = await this.$refs['form'].validate().then(res => res).catch(error => error);
                    if (!valid) return
                    const resp = await this.$http.post(`/admin/company/${this.$route.params.id}/user/`, this.form)
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.$message.success("保存成功")
                        this.getData()
                        this.dialogVisible = false
                    }
                } catch (e) {
                    this.$message.error(e)
                }
            },
            async getData() {
                const resp = await this.$http.get(`/admin/company/${this.$route.params.id}/user/`)
                console.log(resp)
                this.table.data = resp.data.data.records
                this.table.total = resp.data.data.total
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
