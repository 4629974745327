<template>
    <div class="page">
        <div class="page-header hidden-sm-and-down">
            <div style="float:right">
                <slot name="buttons">
                    <el-button :type="btn.type" size="small" :icon="btn.icon" v-for="(btn, i) in currentTable.buttons" :key="i"
                               @click="handleButton(btn)">{{btn.label}}
                    </el-button>
                </slot>
            </div>
            <el-page-header @back="$router.go(-1)" :content="title"/>
        </div>
        <div class="page-search">
            <slot name="search">
                <el-form :inline="true" :model="form" @submit.native.prevent>
                    <el-form-item :class="item.class" v-for="(item,idx) in currentTable.search" :key="idx">
                        <el-input size="small" v-model="form[item.key]" :placeholder="item.placeholder"
                                  v-if="item.type==='input'"></el-input>
                        <el-select size="small" v-model="form[item.key]" :placeholder="item.placeholder"
                                   v-if="item.type==='select'">
                            <el-option v-for="(o,j) in item.data" :key="j" :label="o.label"
                                       :value="o.value"></el-option>
                        </el-select>
                        <el-date-picker size="small" v-model="form[item.key]" type="date"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                        :placeholder="item.placeholder" v-if="item.type === 'date'"></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="small" plain type="primary" @click="handleSearch" native-type="submit">查询
                        </el-button>
                    </el-form-item>
                </el-form>
            </slot>
        </div>
        <div class="page-main">
            <slot></slot>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                           :total="currentTable.total" :page-size="form.pageSize" @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"/>
        </div>
        <slot name="dialog"></slot>
    </div>
</template>


<script>
    export default {
        name: 'zk-admin-table',
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: '页面标题'
            },
            table: {
                default: {
                    search: [],
                    columns: [],
                    data: [],
                    total: 0,
                },
            },
        },
        model: {
            prop: 'table',
            event: 'change'
        },
        data() {
            return {
                currentTable: this.table,
                form: {
                    page: 1,
                    pageSize: 20,
                }
            }
        },
        watch: {
            table: {
                immediate: true,
                handler(val) {
                    if (val.length > 0) {
                        this.currentTable = val
                    }
                }

            },
            currentTable: {
                immediate: true,
                handler(newVal) {
                    this.$emit('change', newVal)
                }

            }

        },
        methods: {
            handleButton(btn) {
                this.$store.commit('setRouterHistory', {label: btn.label, path: btn.path})
                this.$router.push({
                    path: btn.path
                })
            },
            handleCurrentChange(e) {
                this.form.page = e
                this.handleSearch()
            },
            handleSizeChange(e) {
                this.form.pageSize = e
                this.handleSearch()
            },
            handleSearch() {
                this.$emit('search', this.form)
                return false
            },
        },
        mounted() {
            this.table.search.forEach(e => {
                if (e.value) {
                    this.form[e.key] = e.value
                }
            })
            this.form.pageSize = this.table.pageSize
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        display: flex;
        flex-direction: column;
        /*justify-content: flex-start;*/
        /*align-items: flex-start;*/
        height: 100%;

        .page-header {
            padding-bottom: 10px;
        }

        .page-search {
            background-color: #f7f7f8;
            padding: 10px;
            border: 1px solid #EBEEF5;
            border-bottom: 0px;
            text-align: center;

            .el-form-item {
                margin: 0 5px;
            }

        }

        .page-main {
            flex: 1;
            min-height: 100px;
        }

        .page-footer {
            text-align: center;
            padding-top: 20px;
        }
    }
</style>
