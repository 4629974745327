<template>
    <zk-admin-table :title="$t('menu.company_index')" @search="handleSearch" :table="table"
                    :loading="loading">
        <div slot="search">
            <div style="text-align: left">
                <el-button size="small" style="margin-left: 10px" type="primary" @click="handleAdd">新建</el-button>
            </div>
            <!--            <el-form :inline="true" size="small">-->
            <!--                <el-form-item>-->
            <!--                    <el-input v-model="form.name" clearable />-->
            <!--                </el-form-item>-->
            <!--                <el-form-item>-->
            <!--                <el-button size="small" style="margin-left: 10px" type="primary" @click="handleSet">筛选</el-button>-->
            <!--                </el-form-item>-->
            <!--            </el-form>-->
        </div>
        <el-table @selection-change="handleSelectionChange" @filter-change="filterChange" stripe :data="table.data"
                  style="width: 100%"
                  v-loading="loading" border height="100%">
            <el-table-column type="selection" align="center" width="50"/>
            <el-table-column align="center"
                             v-for="(v, idx) in table.columns"
                             :prop="v.field"
                             :label="v.title"
                             :width="v.width"
                             :formatter="v.formatter"
                             :filters="v.filters"
                             :key="idx">
            </el-table-column>
            <el-table-column align="center" width="120" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="handleUpdate(scope.row)">设置</el-button>
                    <el-button type="text" size="mini" @click="handleUser(scope.row)">用户</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div slot="dialog">
            <el-dialog title="设置" :visible.sync="dialogVisible" width="40%">
                <el-form ref="form" :model="form" label-width="150px" size="small">
                    <el-form-item label="租户名称" prop="company_name"
                                  :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model="form.company_name"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人" prop="contact_name"
                                  :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model="form.contact_name"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="phone"
                                  :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model="form.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="SN 前缀" prop="sn"
                                  :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.device_sn_prefix"></el-input>
                    </el-form-item>
                    <el-form-item label="设备通信密钥" prop="version"
                                  :rules="{ required: false, message: '版本不能为空', trigger: 'blur'}">
                        <el-input v-model="form.device_key" :disabled="isdisabled"></el-input>
                    </el-form-item>
                    <el-form-item label="租户编码" prop="tenant_id"
                                  :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model="form.tenant_id" :disabled="isdisabled"></el-input>
                    </el-form-item>
                    <el-form-item label="页面和接口显示SN" prop="show_sn"
                                  :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                        <el-radio-group v-model="form.show_sn">
                            <el-radio :label="true">是</el-radio>
                            <el-radio :label="false">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="页面和接口显示别名" prop="show_sn_prefix"
                                  :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                        <el-radio-group v-model="form.show_sn_prefix">
                            <el-radio :label="true">是</el-radio>
                            <el-radio :label="false">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="handleUpdateSubmit">确 定</el-button>
                  </span>
            </el-dialog>
        </div>
    </zk-admin-table>
</template>

<script>
    export default {
        name: 'company_index',
        path: '/main/company/index',
        data() {
            return {
                loading: false,
                dialogVisible: false,
                form: {
                    doctor: '',
                    multipleSelection: [],
                },
                params: {
                    page: 1,
                    pageSize: 20,
                    doctor: []
                },
                isdisabled: true,
                table: {
                    buttons: [],
                    search: [],
                    columns: [
                        {title: '名称', field: 'company_name', width: ''},
                        {title: '联系人', field: 'contact_name', width: '', formatter: null},
                        {title: '联系电话', field: 'phone', width: '', filters: []},
                        // {title: 'email', field: 'email', width: '290'},
                        {title: '创建时间', field: 'created_date', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
            }
        },
        methods: {
            async handleSet() {
                this.getData()
            },
            handleAdd() {
                let tenant_id = this.generateClientID()
                this.form = {tenant_id: tenant_id, device_key: this.strToHexCharCode(tenant_id)}
                this.dialogVisible = true
            },
            handleUpdate(row) {
                var temp = JSON.stringify(row)
                this.form = JSON.parse(temp)
                this.dialogVisible = true
            },
            async handleUpdateSubmit() {
                try {
                    const resp = await this.$http.post(`/admin/company/`, this.form)
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.$message.success("保存成功")
                        this.getData()
                        this.dialogVisible = false
                    }
                } catch (e) {
                    this.$message.error(e)
                }
            },
            handleUser(row) {
                this.$router.push(`/main/company/${row.id}/user`)
            },
            handleSelectionChange(val) {
                this.form.multipleSelection = val;
            },
            handleSearch(e) {
                this.params.page = e.page
                this.params.pageSize = e.pageSize
                this.getData()
            },
            filterChange(e) {
                this.params.doctor = e['el-table_1_column_4']
                this.getData()
            },
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/admin/company/`, {params: this.params})
                    this.table.data = resp.data.data || []
                    this.table.total = this.table.data.length
                } catch (e) {
                    console.log(111, e)
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            generateClientID() {
                var clientID = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                var numPossible = possible.length;
                for (var i = 0; i < 8; i++) {
                    clientID += possible.charAt((Math.random() * numPossible) | 0);
                }
                return clientID;
            },
            strToHexCharCode(str) {
                // if (str === "")
                //     return "";
                // var hexCharCode = [];
                // // hexCharCode.push("0x");
                // var key = "ABCDEF0123456789"
                // for (var i = 0; i < str.length; i++) {
                //     hexCharCode.push((str.charCodeAt(i)).toString(16));
                // }
                var kv = ["A","B","C","D","E","F","0","1","2","3","4","5","6","7","8","9"]
                var key = this.shuffle(kv);
                return key.join("").toUpperCase();
                // return hexCharCode.join("").toUpperCase();
            },
            //乱序方法
            shuffle(arr) {
                let temp, length = arr.length;
                for (let i = 0; i < length - 1; i++) {
                    let index = Math.floor(Math.random() * (length --));
                    temp = arr[index];
                    arr[index] = arr[length];
                    arr[length] = temp;
                }
                return arr;
            },

        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .el-form-item {
    }
</style>
