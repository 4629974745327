export default {
    app_title: 'TMS 运营后台',
    app: {
        title: 'TMS 运营后台',
        home: '首页',
        passwd: '修改密码',
        logout: '退出',
    },
    menu: {
        dashboard: '系统首页',
        company: '租户管理',
        company_form: '租户开通',
        company_index: '租户列表',
        company_overdue: '即将到期',
        device: '设备管理',
        device_index: '蓝牙设备',
        device_ap: 'AP',
        oplog: '系统日志',
    },
    message: {
        title: '你好',
        theme: '主题'
    },
    skin: {
        Blue: '蓝色',
        Green: '绿色'
    }
}
